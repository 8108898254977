import React from "react";
import "./App.css";
import HomePage from "./components/HomePage";
import Home from "./components/Home";
function App() {
  return (
    <div className="App">
  
      <Home />
      <br></br>
    </div>
  );
}

export default App;
