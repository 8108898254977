import React from "react";
import ProjectCard from "./ProejctCard";

function ProjectList() {
  return (
    <div>
      <ProjectCard />
    </div>
  );
}

export default ProjectList;
